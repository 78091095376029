import React from 'react';
import {read, write} from "xlsx";
import {readExcelFileToData} from "../utils/file-utils";
import {transmuteReport} from "./services/transaction-report-service";
import {UploadFileBox} from "../common/components/UploadFileBox";

export function FixReport() {

    async function handleChanged(file: File) {
        if(file !== undefined) {
            const data = await readExcelFileToData(file);
            const parsed = read(data, {type: 'array'});
            const newBook = transmuteReport(parsed);

            const results = write(newBook, {bookType: 'xlsx', type: 'array'});

            const blob = new Blob([results], {type: 'application/xlsx'});
            const finishedFile = new File([blob], 'split-report.xlsx', {type: 'application/xlsx'})
            const url = URL.createObjectURL(finishedFile);

            // @ts-ignore
            const isFirefox = typeof window['InstallTrigger'] !== 'undefined';
            if(isFirefox) {
                window.open(url);
            } else {
                const link = document.createElement('a')
                link.href = url;
                link.download = 'split-report.xlsx';
                link.click();
            }

        } else {
            console.log('File was unset');
        }
    }

    return (
        <div className="flex justify-center pt-12 max-w-xl mx-auto">
            <div className="w-full ml-4 mr-4 items-center justify-center">
                <h2 className="text-xl text-center">Transaction Report Splitter</h2>

                <div>
                    <UploadFileBox onChange={handleChanged}/>
                </div>
            </div>
        </div>
    )
}