export async function readExcelFileToData(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            resolve(e.target?.result as ArrayBuffer);
        }

        reader.onerror = (e) => {
            reject(e);
        }
        reader.readAsArrayBuffer(file)
    })
}