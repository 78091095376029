import React from "react";
import { FileUploader } from "react-drag-drop-files";

export function UploadFileBox(props: { onChange: (file: File) => Promise<void> }) {
    function handleChange(file: File) {
        return props.onChange(file);
    }

    return (
        <FileUploader classes="upload-box" handleChange={handleChange} name="file" />
    )
}