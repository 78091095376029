import {WorkBook, WorkSheet, utils as xlsxUtils} from "xlsx";
const PRIMARY_SHEET = 'Transaction Report';
const HEADER_ROW = 5;
const FIRST_DATA_ROW = 6;

interface Section {
    name: string;
    startRow: number;
    endRow: number;
}

function validate(book: WorkBook): string[] | 'Ok' {
    const errors = [];
    if (!book.Sheets[PRIMARY_SHEET]) {
        errors.push(`${PRIMARY_SHEET} sheet is missing`);
    }

    if(errors.length > 0) {
        return errors;
    } else {
        return 'Ok';
    }
}

function findEndOfSection(primarySheet: WorkSheet, currentRow: number, customerName: string): number {
    let row = currentRow;
    while (!(primarySheet['A' + row]?.v ?? "").startsWith(`Total for ${customerName}`)) {
        row++;
    }

    return row;
}

function breakoutSections(primarySheet: WorkSheet): Section[] {
    let currentRow = FIRST_DATA_ROW;
    const sections: Section[] = [];

    while (primarySheet['A' + currentRow].v !== "TOTAL") {
        const customer = primarySheet['A' + currentRow].v;
        const endingNumber = findEndOfSection(primarySheet, currentRow, customer);

        sections.push({
            name: customer,
            startRow: currentRow,
            endRow: endingNumber
        })

        currentRow = endingNumber + 1;
    }

    return sections;
}
function createRollup(primarySheet: WorkSheet, sections: Section[]): any {
    const rollup = [];

    for(const section of sections) {
        rollup.push({
            "Customer": section.name,
            "Total": primarySheet['I' + section.endRow].v
        })
    }

    return rollup;
}

function breakoutCustomers(book: WorkBook) {
    const primarySheet = book.Sheets[PRIMARY_SHEET];

    const sections = breakoutSections(primarySheet);

    const rollupData = createRollup(primarySheet, sections);
    const rollup = xlsxUtils.json_to_sheet(rollupData);
    xlsxUtils.book_append_sheet(book, rollup, "Rollup");

    for(const section of sections) {
        const header = xlsxUtils.sheet_to_json(primarySheet, {range: `A${HEADER_ROW}:K${HEADER_ROW}` + section.endRow, defval: "", header:1})[0] as string[];
        const data = xlsxUtils.sheet_to_json(primarySheet, {range: "A" + section.startRow + ":K" + section.endRow, header});

        const newSheet = xlsxUtils.json_to_sheet(data, {header});
        xlsxUtils.book_append_sheet(book, newSheet, section.name);
    }
}

export function transmuteReport(book: WorkBook): WorkBook {
    const results = validate(book);

    if (results !== 'Ok') {
        throw new Error(results.join('\n'));
    }

    breakoutCustomers(book);

    return book;
}