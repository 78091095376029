import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import {
    createBrowserRouter, Navigate,
    RouterProvider,
} from "react-router-dom";
import {FixReport} from "./demp-tools/FixReport";
import {AmazonReferral} from "./AmazonReferral";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to={"/demp-tools/fix-report"} />
    },
    {
        path: "/amazon",
        element: <AmazonReferral />
    },
    {
        path: "/demp-tools/fix-report",
        element: <FixReport />
    }
]);

const rootElement = document.getElementById("root")
if(rootElement === null) {
    throw new Error("unable to find an element with id 'root'");
}

ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
