import React, {useState} from "react";

enum UIState {
    UnableToParseUrl,
    Empty
}

type State = UIState | string;

function createLink(url: string): State {
    var urlObject = new URL(url);
    var paths = urlObject.pathname.split("/");

    var reversed = paths.reverse();
    var asin = reversed.find((value) => value.length === 10 && !value.startsWith("ref"));

    if(asin === undefined) {
        return UIState.UnableToParseUrl;
    } else {
        return `https://www.amazon.com/dp/${asin}?tag=mcclellandesi-20`;
    }
}

function Output(props: { url: State }) {
    if(props.url === UIState.Empty) {
        return <></>
    } else if(props.url === UIState.UnableToParseUrl) {
        return <div>Unable to parse URL</div>
    } else {
        return (
            <OutputUrl url={props.url}/>
        )
    }
}

function OutputUrl(props: { url: string }) {
    if(props.url === "") {
        return <></>
    } else {
        return (
            <div>
                <div>
                    <textarea className="w-full" value={props.url} readOnly={true} />
                </div>
                <div className="pt-5">
                    <a href={props.url} className="ring-gray-500 ring-1 rounded text-gray-800 hover:text-white px-3 py-[13px] hover:bg-gray-800" target="_blank" rel="noreferrer">Open in new tab</a>
                    <button className="ring-gray-500 ring-1 rounded text-gray-800 hover:text-white px-3 py-[12px] hover:bg-gray-800" onClick={() => navigator.clipboard.writeText(props.url)}>Copy to clipboard</button>
                </div>
            </div>
        )
    }
}

export function AmazonReferral() {
    var [generatedUrl, setGeneratedUrl] = useState(UIState.Empty) as [State, (value: State) => void]

    function handleChanged(event: React.ChangeEvent<HTMLInputElement>) {
        console.log(event.target.value);

        if(event.target.value === undefined) {
            setGeneratedUrl("");
        } else {
            setGeneratedUrl(createLink(event.target.value));
        }
    }

    return (
        <div className="flex justify-center pt-12 max-w-xl mx-auto">
            <div className="w-full ml-8 mr-8 items-center justify-center">
                <h2 className="text-xl text-center">Amazon URL Generator</h2>

                <div>
                    <input type="text" className="w-full mb-5 mt-5" placeholder="Enter the Amazon URL" onChange={handleChanged}/>
                </div>

                <Output url={generatedUrl}/>
            </div>
        </div>
    )
}